<template>
    <div>
        <b-table
            ref="table"
            show-empty
            :borderless="currentTable === 3 ? true : false"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="searchFilter"
            :items="currentTableData"
            :fields="currentTableFields"
            :per-page="perPage"
            :current-page="currentPage"
            hover
            @refresh="$emit('on-refresh')"
            @row-clicked="(row) => (Array.isArray(row.alerts) ? openGlobalAlertsModal(row) : toggleRow(row))"
            @filtered="setRows"
        >
            <!-- MEDIAS  -->
            <template #cell(medias)="data">
                <div style="display: flex; gap: 15px; align-items: center; margin-left: 16px">
                    <div v-if="data.item.icon">
                        <SvgIcon :name="data.item.icon" style="width: 20px" />
                    </div>
                    <div style="width: 100%; min-width: 0">
                        <div style="display: flex; gap: 5px; font-size: 13px; font-weight: 600; line-height: 22px">
                            <div class="data-city" style="display: flex; gap: 5px">
                                <span>{{ data.item.point_code + ` - ` ? data.item.point_code : '' }}</span>
                                <span>{{ data.item.city ? data.item.city : '' }}</span> -
                            </div>

                            <span class="data-name">{{ data.item.name ? data.item.name : '' }}</span>
                        </div>
                        <div style="font-size: 11px; font-style: normal; font-weight: 400; color: #93909d">
                            <span>{{ data.item.financialGroupName ? data.item.financialGroupName : '' }}</span>
                        </div>
                    </div>
                </div>
            </template>

            <template #cell(marque)="data">
                <div v-if="data.item.brandIcon" style="display: flex; justify-content: end">
                    <SvgIcon :name="data.item.brandIcon" style="width: 24px" />
                </div>
            </template>
            <template #cell(startDateMedia)="data">
                <div class="custom-date main-element">{{ data.item.startDateMedia ? formatDate(data.item.startDateMedia) : '' }}</div>
            </template>

            <template #cell(endDateMedia)="data">
                <div class="custom-date main-element">{{ data.item.endDateMedia ? formatDate(data.item.endDateMedia) : '' }}</div>
            </template>

            <template #cell(nb_metrics)="data">
                <div style="text-align: right; font-size: 13px; font-weight: 700">
                    {{ data.item.nb_metrics ? data.item.nb_metrics : 0 }} / {{ calculateDays(data.item.start_date, data.item.end_date) }}
                </div>
            </template>

            <template #cell(ratio)="data">
                <div class="flex" style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 15px">
                    <div v-if="!data.item.details.package" style="flex-direction: column; width: 40%; text-align: right">
                        <div style="font-size: 13px; font-weight: 700; color: #93909d">
                            <span :style="{ color: getAlertColor(data.item) }">
                                {{
                                    data.item.goal === 'impressions'
                                        ? data.item.details.sum_of_impressions !== undefined
                                            ? defineAlertColor(data.item.details.package, data.item.details.sum_of_impressions, data.item.ratio, data.item.ratioAlert).value
                                            : '--'
                                        : data.item.goal === 'video_quartile_p100'
                                        ? data.item.details.sum_of_views !== undefined
                                            ? defineAlertColor(data.item.details.package, data.item.details.sum_of_views, data.item.ratio, data.item.ratio, data.item.ratioAlert).value
                                            : '--'
                                        : data.item.details.sum_of_clicks !== undefined
                                        ? defineAlertColor(data.item.details.package, data.item.details.sum_of_clicks, data.item.ratio, data.item.ratioAlert).value
                                        : '--'
                                }}
                            </span>
                        </div>
                        <div style="font-size: 11px">
                            <span>{{ data.item.details.quantity ? data.item.details.quantity.toLocaleString('fr-FR') : '-- ' }}</span>
                            <span class="align-self-end">
                                {{ data.item.goal === 'impressions' ? 'impressions' : data.item.goal === 'video_quartile_p100' ? 'views' : 'clics' }}
                            </span>
                        </div>
                    </div>
                    <div v-else style="flex-direction: column; width: 60%; text-align: right">
                        <div style="font-size: 13px; font-weight: 700; color: #93909d">
                            <span v-if="data.item.details.sum_of_budget" :style="data.item.details.sum_of_budget !== null ? { color: getAlertColor(data.item) } : null">
                                {{
                                    data.item.details.sum_of_budget !== null
                                        ? defineAlertColor(data.item.details.package, data.item.details.sum_of_budget, data.item.ratio, data.item.ratioAlert).value
                                        : '--'
                                }}
                                €
                            </span>
                            <span v-else>--</span>
                        </div>
                        <div style="font-size: 11px">
                            <span>{{ data.item.details.provider_data.budget ? (data.item.details.provider_data.budget / 100).toFixed(2).toLocaleString('fr-FR') + '€' : '-- ' }} </span>
                            <span class="align-self-end">commandé</span>
                        </div>
                    </div>

                    <div>
                        <RadialBarChart
                            v-if="data.item.ratioAlert"
                            :typeColor="data.item.ratioAlert"
                            :value="data.item.ratio ? data.item.ratio : 0"
                            :dataLabelFontSize="9"
                            :chartWidth="100"
                            style="width: 5rem"
                        />
                    </div>
                </div>
            </template>

            <template #cell(actionsMedias)="data">
                <div class="flex align-items-center flex-nowrap justify-content-end" style="gap: 15px; align-items: center">
                    <div style="width: 20px" @click="seeDetails(data.item.parent, data.item.media_support_code)" data-toggle="tooltip" data-placement="top" title="Accéder aux détails de la campagne">
                        <img src="@/assets/images/icons/eye-icon.png" alt="Eye" style="width: 16px; height: 16px; margin: auto" class="flex" />
                    </div>

                    <div
                        v-if="(data.item.status === 0 || data.item.status === 1) && data.item.details.provider_data.id"
                        @click="copyMediaId(data.item.details.provider_data.id)"
                        style="width: 16px"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Copier l’ID du média"
                    >
                        <img src="@/assets/images/icons/link-icon.svg" alt="Linked media" />
                    </div>

                    <b-dropdown size="lg" right variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <img src="@/assets/images/icons/dots-vertical.png" alt="Actions" />
                        </template>

                        <b-dropdown-item v-if="data.item.status === 0 || data.item.status === 1" @click="openZipCodeModal(data.item.brand, data.item.point_code)" class="hoverable-container">
                            <span>Copier codes postaux</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                            v-if="(data.item.status === 0 || data.item.status === 1) && !data.item.details.provider_data.id"
                            @click="$emit('handle-dsp', data.item)"
                            class="hoverable-container"
                        >
                            <span>Lier le média</span>
                        </b-dropdown-item>

                        <b-dropdown-item v-if="(data.item.status === 0 || data.item.status === 1) && data.item.details.provider_data.id" @click="modifyProviderId(data.item)"
                            >Modifier l’ID du média</b-dropdown-item
                        >

                        <b-dropdown-item
                            v-if="
                                data.item.status === 0 &&
                                (facebookMedia.includes(data.item.media_support_code) ||
                                    facebookMedia.includes(data.item.media_support_code) ||
                                    facebookMedia.includes(data.item.media_support_code) ||
                                    facebookMedia.includes(data.item.media_support_code)) &&
                                !data.item.details.creaData.code
                            "
                            @click="$emit('creation-facebook', data.item.parent, data.item.campaign_code)"
                            >Créer le média provider Facebook</b-dropdown-item
                        >

                        <b-dropdown-item
                            v-if="
                                data.item.status === 0 &&
                                (validMedia.includes(data.item.media_support_code) || validMediaNoUrl.includes(data.item.media_support_code)) &&
                                !data.item.details.provider_data.id
                            "
                            @click="$emit('creation-provider', data.item.parent, data.item.campaign_code)"
                            >Créer le média provider</b-dropdown-item
                        >

                        <b-dropdown-item
                            v-if="
                                (data.item.status === 0 || data.item.status === 1) &&
                                (validMedia.includes(data.item.media_support_code) || validMediaNoUrl.includes(data.item.media_support_code)) &&
                                data.item.details.provider_data.id
                            "
                            @click="$emit('creation-provider', data.item.parent, data.item.campaign_code)"
                            >Modifier le média provider</b-dropdown-item
                        >

                        <b-dropdown-item v-if="data.item.status === 0 || data.item.status === 1" @click="bannerDownloadMedia(data.item)">Télécharger la bannière</b-dropdown-item>

                        <b-dropdown-item v-if="data.item.status === 1" @click="$emit('add-theme', data.item.start_date, data.item.end_date, data.item.parent)">Remplacer la bannière</b-dropdown-item>

                        <b-dropdown-item
                            v-if="data.item.status === 0 || data.item.status === 1"
                            @click="$emit('update-date', data.item.start_date, data.item.end_date, data.item.campaign_code, data.item.parent)"
                        >
                            Modifier la date
                        </b-dropdown-item>

                        <!-- <b-dropdown-item
                            v-if="(data.item.status === 0 || data.item.status === 1) && data.item.details && data.item.details.creaData && data.item.details.creaData.code"
                            @click="$emit('creation-provider', data.item.parent)"
                            >Modifier média provider</b-dropdown-item
                        > -->

                        <b-dropdown-item v-if="data.item.status === 1" @click="$emit('sync-metrics', data.item.campaign_code)">Synchroniser les metrics</b-dropdown-item>
                        <b-dropdown-item v-if="data.item.status === 1" @click="$emit('force-metrics', data.item.campaign_code)">Forcer les metrics</b-dropdown-item>
                        <b-dropdown-item v-if="data.item.status === 0 || data.item.status === 1" @click="openReplaceMediaModal(data.item)">Supprimer / Remplacer</b-dropdown-item>
                        <!-- <b-dropdown-item v-if="data.item.status === 0 || data.item.status === 1" @click="$emit('cancel-camp', data.item.parent)">Annuler</b-dropdown-item> -->
                    </b-dropdown>
                </div>
            </template>

            <!-- CAMPAGNES  -->

            <template #cell(campaigns)="data">
                <div style="display: flex; gap: 15px; align-items: center; margin-left: 16px">
                    <div v-if="data.item.brandIcon">
                        <SvgIcon :name="data.item.brandIcon" style="width: 20px" />
                    </div>
                    <div style="width: 100%; min-width: 0">
                        <div style="display: flex; gap: 5px; font-size: 13px; font-weight: 600; line-height: 22px">
                            <span>{{ data.item.point_code + ` - ` ? data.item.point_code : '' }}</span>
                            <span class="data-city">{{ data.item.details.city + ` - ` ? data.item.details.city : '' }}</span> -
                            <span class="data-name">{{ data.item.name ? data.item.name : '' }}</span>
                        </div>

                        <div style="font-size: 11px; font-style: normal; font-weight: 400; color: #93909d">
                            <span>{{ data.item.details.financialGroupName ? data.item.details.financialGroupName : '' }}</span>
                        </div>
                    </div>
                </div>
            </template>
            <template #cell(children)="data">
                <div style="font-size: 13px; font-weight: 700; text-align: right">{{ data.item.children ? data.item.children : '' }}</div>
            </template>

            <template #cell(start_date)="data">
                <div class="custom-date">{{ data.item.start_date ? formatDate(data.item.start_date) : '' }}</div>
            </template>

            <template #cell(endDateCampaign)="data">
                <div class="custom-date">{{ data.item.endDateCampaign ? formatDate(data.item.endDateCampaign) : '' }}</div>
            </template>

            <template #cell(budget)="data">
                <div v-if="data.item.budget" style="font-size: 13px; text-align: right">{{ data.item.budget ? data.item.budget.toFixed(2) : '— ' }}€</div>
            </template>

            <template #cell(paid)="data">
                <div style="font-size: 13px; text-align: right" v-if="data.item.budget && data.item.left_to_pay && data.item.budget > data.item.left_to_pay">
                    {{ (data.item.budget / 2).toFixed(2) }}€
                </div>
            </template>

            <template #cell(left_to_pay)="data">
                <div style="font-size: 13px; text-align: right">{{ data.item.left_to_pay ? data.item.left_to_pay.toFixed(2) : '— ' }}€</div>
            </template>

            <template #cell(actionsCampaigns)="data">
                <div class="flex align-items-center flex-nowrap justify-content-end" style="gap: 15px">
                    <div style="width: 20px" @click="seeDetails(data.item.campaign_code)" data-toggle="tooltip" data-placement="top" title="Accéder aux détails de la campagne">
                        <img src="@/assets/images/icons/eye-icon.png" alt="Eye" style="width: 16px; height: 16px; margin: auto" class="flex" />
                    </div>
                    <div v-if="data.item.status === 20" style="margin-right: 7px"></div>
                    <b-dropdown size="lg" right variant="link" toggle-class="text-decoration-none" no-caret>
                        <template v-if="data.item.status !== 20" template #button-content>
                            <img src="@/assets/images/icons/dots-vertical.png" alt="More" class="" />
                        </template>

                        <b-dropdown-item v-if="data.item.status === 7" @click="openZipCodeModal(data.item.brand, data.item.point_code)" class="hoverable-container">
                            <span>Copier codes postaux</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                            v-if="
                                (data.item.status === 7 && right == 'trading' && data.item.value === 'traders') ||
                                (data.item.status === 8 && right == 'direction' && data.item.value === 'direction') ||
                                (data.item.status === 8 && right == 'client_service' && data.item.value === 'csm') ||
                                data.item.status === 9
                            "
                            @click="$emit('update-camp-date', data.item.start_date, data.item.end_date, data.item.campaign_code)"
                        >
                            Modifier les dates
                        </b-dropdown-item>

                        <b-dropdown-item v-if="data.item.status === 7" @click="$emit('change-media-id', data.item.details.children)">Modifier l’ID du média</b-dropdown-item>
                        <!-- <b-dropdown-item v-if="data.item.status === 0 || data.item.status === 1 || data.item.status === 11" @click="bannerDownload(data.item)">
                            Télécharger la bannière
                        </b-dropdown-item> -->

                        <!-- <b-dropdown-item v-if="data.item.status === 8 || data.item.status === 9 || data.item.status === 10" @click="downloadReport(data.item)">Télécharger le bilan</b-dropdown-item> -->

                        <!-- <b-dropdown-item v-if="data.item.status === 7" @click="fillWithZeroMetrics(data.item.campaign_code)">Compléter les metrics avec 0</b-dropdown-item>
                        <b-dropdown-item v-if="data.item.status === 7" @click="completeTopTen(data.item.campaign_code)">Compléter les top ten</b-dropdown-item> -->

                        <!-- <b-dropdown-item
                            v-if="data.item.status === 0 || data.item.status === 1 || data.item.status === 5 || data.item.status === 11"
                            @click="$emit('add-theme', data.item.startDate, data.item.endDate, data.item.campaignCode, data.item.pointText)"
                        >
                            Télecharger une autre bannière
                        </b-dropdown-item> -->

                        <b-dropdown-item v-if="data.item.status === 7 || data.item.status === 8 || data.item.status === 10" @click="$emit('download-report', data.item)"
                            >Télécharger le bilan</b-dropdown-item
                        >

                        <b-dropdown-item
                            v-if="
                                (data.item.status === 7 && right == 'trading' && data.item.value === 'traders') ||
                                (data.item.status === 8 && right == 'direction' && data.item.value === 'direction') ||
                                (data.item.status === 8 && right == 'client_service' && data.item.value === 'csm')
                            "
                            @click="tradingRoute(data.item.campaignCode)"
                            class="hoverable-container"
                        >
                            <div>
                                <span>Éditer le bilan</span>
                            </div>
                        </b-dropdown-item>

                        <b-dropdown-item
                            v-if="
                                (data.item.status === 7 && right == 'trading' && data.item.value === 'traders') ||
                                (data.item.status === 8 && right == 'direction' && data.item.value === 'direction') ||
                                (data.item.status === 8 && right == 'client_service' && data.item.value === 'csm')
                            "
                            @click="$emit('open-alert', 'success', data.item.campaignCode)"
                        >
                            Valider le bilan
                        </b-dropdown-item>

                        <b-dropdown-item
                            v-if="data.item.status === 8 && right == 'direction' && data.item.value === 'direction'"
                            @click="$emit('open-alert', 'success', data.item.campaignCode, (campaignReportNotSent = true))"
                            >Valider sans envoi</b-dropdown-item
                        >

                        <b-dropdown-item
                            v-if="
                                (data.item.status === 8 && right == 'direction' && data.item.value === 'direction') ||
                                (data.item.status === 8 && right == 'client_service' && data.item.value === 'csm')
                            "
                            @click="$emit('open-alert', 'danger', data.item.campaignCode)"
                        >
                            Refuser le bilan
                        </b-dropdown-item>

                        <b-dropdown-item v-if="data.item.status === 10 && right == 'direction'" @click="$emit('open-alert', 'primary', data.item.campaignCode)">
                            {{ data.item.left_to_pay <= 0 ? 'Archiver' : 'Envoi du paiement' }}
                        </b-dropdown-item>

                        <!-- <b-dropdown-item v-if="data.item.status === 7" @click="$emit('sync-metrics', data.item.campaign_code)">Synchroniser les metrics</b-dropdown-item>
                        <b-dropdown-item v-if="data.item.status === 7" @click="$emit('force-metrics', data.item.campaign_code)">Forcer les metrics</b-dropdown-item> -->

                        <!-- <b-dropdown-item v-if="data.item.status === 0 || data.item.status === 1 || data.item.status === 11" @click="$emit('creation-provider', data.item.campaignCode)">
                            Créer / modifier la campagne provider
                        </b-dropdown-item> -->

                        <b-dropdown-item v-if="data.item.status === 0 || data.item.status === 11" @click="$emit('cancel-camp', data.item.campaignCode)">
                            <span style="color: red">Annuler la campagne</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </template>

            <template #row-details="row">
                <div v-if="row.item.status >= 7" class="row-details">
                    <b-collapse id="collapse-2" v-model="isCollapsed">
                        <div v-if="row.item.details.children">
                            <b-table :items="row.item.details.children" :fields="mediaFields" :borderless="borderless" tbody-tr-class="custom-row">
                                <template #cell(mediaName)="data">
                                    <div style="display: flex; gap: 15px; align-items: center; margin-left: 16px">
                                        <div v-if="data.item.icon">
                                            <SvgIcon :name="data.item.icon" style="width: 20px" />
                                        </div>
                                        <div style="width: 100%; min-width: 0">
                                            <div style="display: flex; gap: 5px; font-size: 13px; font-weight: 600; line-height: 22px">
                                                <span>{{ data.item.point_code + ` - ` ? data.item.point_code : '' }}</span>
                                                <span class="data-city">{{ data.item.city ? data.item.city : '' }}</span> -
                                                <span class="data-name">{{ data.item.name ? data.item.name : '' }}</span>
                                            </div>
                                            <div style="font-size: 11px; font-style: normal; text-align: left; font-weight: 400; color: #93909d">
                                                <span>{{ data.item.financialGroupName ? data.item.financialGroupName : '' }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(label)="data">
                                    <div style="display: flex; justify-content: end">
                                        {{ data.item.status }}
                                    </div>
                                </template>
                                <template #cell(startDate)="data">
                                    <div style="display: flex; flex-direction: column; align-items: end">
                                        <span style="font-weight: 500; line-height: 18px; font-size: 12px">
                                            {{ data.item.start_date ? formatDate(data.item.start_date) : '' }}
                                        </span>
                                        <span style="font-size: 11px; font-style: normal; color: #93909d; font-weight: 400; line-height: 14px">Début</span>
                                    </div>
                                </template>
                                <template #cell(endDate)="data">
                                    <div style="display: flex; flex-direction: column; align-items: end">
                                        <span style="font-weight: 500; line-height: 18px; font-size: 12px">
                                            {{ data.item.endDateMedia ? formatDate(data.item.endDateMedia) : '' }}
                                        </span>
                                        <span style="font-size: 11px; font-style: normal; color: #93909d; font-weight: 400; line-height: 14px">Fin</span>
                                    </div>
                                </template>
                                <template #cell(metricsDays)="data">
                                    <div style="display: flex; flex-direction: column; align-items: end">
                                        <span style="font-size: 13px; font-weight: 700">
                                            {{ data.item.nb_metrics ? data.item.nb_metrics : 0 }} / {{ calculateDays(data.item.start_date, data.item.end_date) }}
                                        </span>
                                        <span style="font-size: 11px; font-style: normal; color: #93909d; font-weight: 400; line-height: 14px">Metrics / jour</span>
                                    </div>
                                </template>
                                <template #cell(metrics)="data">
                                    <div style="display: flex; justify-content: end; align-items: center">
                                        <div v-if="!data.item.details.package" style="flex-direction: column; text-align: right">
                                            <div style="font-size: 13px; font-weight: 700; color: #93909d">
                                                <span :style="{ color: getAlertColor(data.item) }">
                                                    {{
                                                        data.item.goal === 'impressions'
                                                            ? data.item.details.sum_of_impressions !== undefined
                                                                ? defineAlertColor(data.item.details.package, data.item.details.sum_of_impressions, data.item.ratio, data.item.ratioAlert).value
                                                                : '--'
                                                            : data.item.goal === 'video_quartile_p100'
                                                            ? data.item.details.sum_of_views !== undefined
                                                                ? defineAlertColor(data.item.details.package, data.item.details.sum_of_views, data.item.ratio, data.item.ratioAlert).value
                                                                : '--'
                                                            : data.item.details.sum_of_clicks !== undefined
                                                            ? defineAlertColor(data.item.details.package, data.item.details.sum_of_clicks, data.item.ratio, data.item.ratioAlert).value
                                                            : '--'
                                                    }}
                                                </span>
                                            </div>
                                            <div style="font-size: 11px">
                                                <span style="color: #93909d">{{ data.item.details.quantity ? data.item.details.quantity.toLocaleString('fr-FR') : '-- ' }}</span>
                                                <span class="align-self-end" style="color: #93909d">
                                                    {{ data.item.goal === 'impressions' ? 'impressions' : data.item.goal === 'video_quartile_p100' ? 'views' : 'clics' }}
                                                </span>
                                            </div>
                                        </div>
                                        <div v-else style="flex-direction: column; width: 60%; text-align: right">
                                            <div style="font-size: 13px; font-weight: 700; color: #93909d">
                                                <span v-if="data.item.details.sum_of_budget" :style="data.item.details.sum_of_budget !== null ? { color: getAlertColor(data.item) } : null">
                                                    {{
                                                        data.item.details.sum_of_budget
                                                            ? defineAlertColor(data.item.details.package, data.item.details.sum_of_budget, data.item.ratio, data.item.ratioAlert).value
                                                            : '-- '
                                                    }}
                                                    €
                                                </span>
                                                <span v-else>--</span>
                                            </div>
                                            <div style="font-size: 11px">
                                                <span
                                                    >{{ data.item.details.provider_data.budget ? (data.item.details.provider_data.budget / 100).toFixed(2).toLocaleString('fr-FR') + '€' : '-- ' }}
                                                </span>
                                                <span class="align-self-end">commandé</span>
                                            </div>
                                        </div>
                                        <div>
                                            <RadialBarChart
                                                v-if="data.item.ratioAlert"
                                                :typeColor="data.item.ratioAlert"
                                                :value="data.item.ratio ? data.item.ratio : 0"
                                                :dataLabelFontSize="9"
                                                :chartWidth="100"
                                                style="width: 5rem"
                                            />
                                        </div>
                                    </div>
                                </template>

                                <template #cell(actionsMedia)="data">
                                    <div style="display: flex; justify-content: end; gap: 15px; align-items: center">
                                        <div
                                            v-if="(data.item.status === 0 || data.item.status === 1) && data.item.details.provider_data.id"
                                            @click="copyMediaId(data.item.details.provider_data.id)"
                                            style="width: 16px; cursor: pointer"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Copier l’ID du média"
                                        >
                                            <img src="@/assets/images/icons/link-icon.svg" alt="Linked media" />
                                        </div>
                                        <b-dropdown size="lg" right variant="link" toggle-class="text-decoration-none" no-caret>
                                            <template #button-content>
                                                <img src="@/assets/images/icons/dots-vertical.png" alt="Actions" />
                                            </template>

                                            <b-dropdown-item
                                                v-if="(data.item.status === 0 || data.item.status === 1) && !data.item.details.provider_data.id"
                                                @click="$emit('handle-dsp', data.item)"
                                                class="hoverable-container"
                                            >
                                                <span>Lier le média</span>
                                            </b-dropdown-item>

                                            <b-dropdown-item v-if="data.item.status === 7" @click="fillWithZeroMetrics(data.item.campaign_code)">Compléter les metrics avec 0</b-dropdown-item>
                                            <b-dropdown-item v-if="data.item.status === 7" @click="completeTopTen(data.item.campaign_code)">Compléter les top ten</b-dropdown-item>

                                            <b-dropdown-item v-if="data.item.status === 0" @click="openReplaceMediaModal(data.item)">Supprimer / Remplacer</b-dropdown-item>

                                            <!-- <b-dropdown-item v-if="data.item.status === 0" @click="$emit('creation-provider', data.item.parent)">Créer / Modifier le média (TTD)</b-dropdown-item> -->

                                            <b-dropdown-item v-if="data.item.status === 0 || data.item.status === 1" @click="bannerDownloadMedia(data.item)">Télécharger la bannière</b-dropdown-item>

                                            <b-dropdown-item v-if="data.item.status === 1" @click="$emit('add-theme', data.item.start_date, data.item.end_date, data.item.parent)">
                                                Remplacer la bannière
                                            </b-dropdown-item>

                                            <b-dropdown-item v-if="data.item.status === 1" @click="$emit('change-media-id', data.item)">Modifier l’ID du média</b-dropdown-item>

                                            <b-dropdown-item
                                                v-if="data.item.status === 0 || data.item.status === 1"
                                                @click="$emit('update-date', data.item.start_date, data.item.end_date, data.item.campaign_code, data.item.parent)"
                                            >
                                                Modifier la date
                                            </b-dropdown-item>

                                            <b-dropdown-item v-if="data.item.status === 1" @click="$emit('sync-metrics', data.item.campaign_code)">Synchroniser les metrics</b-dropdown-item>

                                            <b-dropdown-item v-if="data.item.status === 7" @click="$emit('sync-metrics', data.item.campaign_code)">Synchroniser les metrics</b-dropdown-item>
                                            <b-dropdown-item v-if="data.item.status === 1 || data.item.status === 7" @click="$emit('force-metrics', data.item.campaign_code)"
                                                >Forcer les metrics</b-dropdown-item
                                            >

                                            <!-- <b-dropdown-item v-if="data.item.status === 0" @click="$emit('cancel-camp', data.item.parent)">Annuler</b-dropdown-item> -->
                                        </b-dropdown>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                    </b-collapse>
                </div>
            </template>

            <!-- ALERTS  -->

            <template #cell(campaignsAlerts)="data">
                <div v-if="data.item.code !== 999 && data.item.type !== 'globalAlert'" style="display: flex; gap: 15px; align-items: center" :class="data.item.isDanger ? 'is-danger' : 'is-warning'">
                    <div v-if="data.item.icon">
                        <SvgIcon :name="data.item.icon" style="width: 20px" />
                    </div>
                    <div style="width: 100%; min-width: 0">
                        <div style="display: flex; justify-content: flex-start; gap: 5px; font-size: 13px; font-weight: 600; line-height: 22px">
                            <div class="data-city" style="display: flex; gap: 5px">
                                <span>{{ data.item.details.point_code + ` - ` ? data.item.details.point_code : '' }}</span>
                                <span>{{ data.item.details.city ? data.item.details.city : '' }}</span> -
                            </div>

                            <span class="data-name">{{ data.item.details.name ? data.item.details.name : '' }}</span>
                        </div>
                        <div style="font-size: 11px; font-style: normal; font-weight: 400; color: #93909d">
                            <span>{{ data.item.details.financialGroupName ? data.item.details.financialGroupName : '' }}</span>
                        </div>
                    </div>
                </div>

                <div v-else :class="data.item.isDanger ? 'is-danger' : 'is-warning'">
                    <div :class="data.item.isLast ? 'is-last' : ''" style="font-size: 13px; line-height: 18px">
                        <span style="font-weight: 600">
                            {{ data.item.alerts.length }}
                        </span>
                        <span>
                            {{ data.item.label }}
                        </span>
                    </div>
                </div>
            </template>

            <template #cell(marqueCampAlerts)="data">
                <div v-if="data.item.brandIcon && data.item.code !== 999 && data.item.type !== 'globalAlert'">
                    <SvgIcon :name="data.item.brandIcon" style="width: 24px" />
                </div>
            </template>
            <template #cell(startDateCampAlert)="data">
                <div v-if="data.item.start_date && data.item.code !== 999 && data.item.type !== 'globalAlert'" class="cell-container">
                    <div class="custom-date" :class="{ danger: data.item.type === 'metrics_first_day' }">
                        {{ formatDate(data.item.start_date) }}
                    </div>
                    <div class="secondary-element">Début</div>
                </div>
            </template>

            <template #cell(endDateCampAlert)="data">
                <div v-if="data.item.end_date && data.item.code !== 999 && data.item.type !== 'globalAlert'" class="cell-container">
                    <div class="custom-date main-element">{{ formatDate(data.item.end_date) }}</div>
                    <div class="secondary-element">Fin</div>
                </div>
            </template>

            <template #cell(nbMetricsAlerts)="data">
                <div class="cell-container" v-if="data.item.code !== 999 && data.item.type !== 'globalAlert'" style="font-weight: bold">
                    <div class="main-element" :class="{ danger: data.item.type === 'metrics_insufficient' || data.item.type === 'metrics_missing' || data.item.type === 'metrics_first_day' }">
                        {{ data.item.details.nb_metrics ? data.item.details.nb_metrics : '--' }}
                        /
                        <span>{{ data.item.details.totalDaysCampaign ? data.item.details.totalDaysCampaign : '--' }}</span>
                    </div>
                    <div class="secondary-element">Metrics / jour</div>
                </div>
            </template>

            <template #cell(budgetAlert)="data">
                <div class="flex" style="flex-direction: row; flex-wrap: nowrap; gap: 10px; width: 10rem; white-space: nowrap" v-if="data.item.code !== 999 && data.item.type !== 'globalAlert'">
                    <div class="cell-container">
                        <div class="main-element bold" :class="{ danger: data.item.type === 'budget_critical', vigilant: data.item.type === 'budget_vigilant' }">
                            {{ data.item.details.actualSpentBudget ? Math.floor(data.item.details.actualSpentBudget).toLocaleString('fr-FR') + '  € ' : '--' }}
                        </div>

                        <div class="secondary-element">
                            {{ data.item.details.price_excluding_taxes ? data.item.details.price_excluding_taxes.toLocaleString('fr-FR') + ' € ' : '--' }}
                        </div>
                    </div>

                    <RadialBarChart
                        style="width: 5rem"
                        :value="data.item.details.budgetRatio ? data.item.details.budgetRatio : 0"
                        :alertColor="{ danger: data.item.type === 'budget_critical', vigilant: data.item.type === 'budget_vigilant' }"
                        :dataLabelFontSize="9"
                        :chartWidth="100"
                    />
                </div>
            </template>

            <template #cell(ratioAlert)="data">
                <div
                    v-if="data.item.type !== 'unlink' && data.item.type !== 'globalAlert' && data.item.code !== 999 && !data.item.details.package"
                    class="flex"
                    style="flex-direction: row; flex-wrap: nowrap; gap: 10px; width: 10rem; white-space: nowrap"
                >
                    <div class="cell-container">
                        <div
                            class="main-element bold"
                            v-if="data.item.details.goal === 'impressions' && data.item.details.sum_of_impressions"
                            :class="{ danger: data.item.type === 'perf_critical', vigilant: data.item.type === 'perf_vigilant' }"
                        >
                            {{ data.item.details.sum_of_impressions.toLocaleString('fr-FR') }}
                        </div>

                        <div
                            class="main-element bold"
                            v-else-if="data.item.details.goal === 'clicks' && data.item.details.sum_of_clicks"
                            :class="{ danger: data.item.type === 'perf_critical', vigilant: data.item.type === 'perf_vigilant' }"
                        >
                            {{ data.item.details.sum_of_clicks.toLocaleString('fr-FR') }}
                        </div>

                        <div class="main-element bold" v-else-if="data.item.details.sum_of_views" :class="{ danger: data.item.type === 'perf_critical', vigilant: data.item.type === 'perf_vigilant' }">
                            {{ data.item.details.sum_of_views.toLocaleString('fr-FR') }}
                        </div>

                        <div class="main-element bold" v-else>--</div>

                        <div class="secondary-element">
                            {{ data.item.details.quantity ? data.item.details.quantity.toLocaleString('fr-FR') : '' }}

                            {{ data.item.details.goal === 'impressions' ? 'imp.' : data.item.details.goal === 'clicks' ? 'clics' : 'vues' }}
                        </div>
                    </div>

                    <RadialBarChart
                        style="width: 4rem"
                        :value="data.item.details.quantityRatio ? data.item.details.quantityRatio : 0"
                        :alertColor="{ danger: data.item.type === 'perf_critical', vigilant: data.item.type === 'perf_vigilant' }"
                        :dataLabelFontSize="9"
                        :chartWidth="100"
                    />
                </div>

                <div v-else-if="data.item.type === 'unlink' && data.item.type !== 'globalAlert'" class="unlink-container">
                    <span style="font-size: 11px">Média non lié</span>
                    <img src="@/assets/images/icons/unlink-icon.svg" alt="Unlinked" style="width: 16px; height: 16px" class="" />
                </div>
            </template>

            <template #cell(actionsAlerts)="data">
                <div class="flex align-center" v-if="data.item.code !== 999 && data.item.type !== 'globalAlert'" style="min-width: 4rem">
                    <div
                        style="width: 20px"
                        @click="data.item.details.isParent ? seeDetails(data.item.campaign_code, data.item.details.media_category) : seeDetails(data.item.parent, data.item.details.media_category)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Accéder aux détails de la campagne"
                    >
                        <img src="@/assets/images/icons/eye-icon.png" alt="Eye" style="width: 16px; height: 16px; margin: auto" class="flex" />
                    </div>

                    <div
                        v-if="data.item.type === 'perf_vigilant' || data.item.type === 'perf_critical' || data.item.type === 'budget_vigilant' || data.item.type === 'budget_critical'"
                        @click="deleteAlert(data.item.code)"
                    >
                        <img src="@/assets/images/icons/checkmark-icon.svg" alt="Check mark" style="margin: auto; padding-left: 0.5rem" class="" />
                    </div>

                    <b-dropdown v-else size="lg" right variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <img src="@/assets/images/icons/dots-vertical.png" alt="Actions" />
                        </template>

                        <b-dropdown-item v-if="data.item.type === 'unlink'" @click="$emit('handle-dsp', data.item)" class="hoverable-container">
                            <span>Lier le média</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-if="!data.item.details.isParent && data.item.type === 'unlink'"
                            @click="$emit('update-date', data.item.start_date, data.item.end_date, data.item.campaign_code, data.item.parent, (isAlert = true), data.item.code)"
                            >Modifier la date</b-dropdown-item
                        >

                        <b-dropdown-item
                            v-if="data.item.type === 'metrics_missing' || data.item.type === 'metrics_insufficient' || data.item.type === 'metrics_first_day'"
                            @click="$emit('sync-metrics', data.item.campaign_code, (isAlert = true), data.item.code)"
                        >
                            Synchroniser
                        </b-dropdown-item>

                        <b-dropdown-item
                            v-if="!data.item.details.isParent && (data.item.type === 'metrics_missing' || data.item.type === 'metrics_insufficient')"
                            @click="fillWithZeroMetrics(data.item.parent, (isAlert = true), data.item.code)"
                            >Metrics manquants à 0</b-dropdown-item
                        >

                        <b-dropdown-item
                            v-if="!data.item.details.isParent && (data.item.type === 'unlink' || data.item.type === 'metrics_missing' || data.item.type === 'metrics_insufficient')"
                            @click="deleteAlert(data.item.code)"
                            >Désactiver l’alerte</b-dropdown-item
                        >

                        <b-dropdown-item style="color: #ef3e3e" v-if="!data.item.details.isParent && data.item.type === 'unlink'" @click="openReplaceMediaModal(data.item, (isAlert = true))">
                            <span style="color: #ef3e3e">Supprimer / Remplacer</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>

                <div v-else class="flex align-center" @click="openGlobalAlertsModal(data.item)" style="gap: 10px; white-space: nowrap; min-width: 4rem">
                    <div style="font-size: 11px; font-weight: 500">Afficher</div>
                    <img src="@/assets/images/icons/array-right-icon.svg" alt="Show" style="margin: auto" />
                </div>
            </template>

            <template #empty="scope">
                <b-skeleton-table :columns="1" :hide-header="true" class="skeleton" v-if="isLoading"></b-skeleton-table>
                <p v-else style="text-align: center; margin-top: 1rem">Pas des campagnes trouvées.</p>
            </template>

            <template #emptyfiltered="scope">
                <p style="text-align: center; margin-top: 1rem">Pas des campagnes trouvées.</p>
            </template>
        </b-table>

        <!-- Modal to delete or update child campaign -->
        <b-modal ref="modal-update-campaign-lever" hide-footer title="Supprimer / Remplacer le média">
            <b-form>
                <span>Êtes-vous sûr de vouloir supprimer le média "{{ media }}" ? </span>

                <b-form-checkbox class="mt-1" v-model="reassignLever">Réaffecter le budget d'un levier à un autre</b-form-checkbox>

                <b-form-group v-if="reassignLever" label="Choix du levier" label-for="select-new-lever" class="mt-1">
                    <b-form-select id="select-new-lever" v-model="selected" :options="leversOptions"></b-form-select>
                </b-form-group>

                <div class="row" v-if="reassignLever && selected">
                    <div class="row ml-1" v-if="!isPackage">
                        <b-form-group label="Nouveau objectif" label-for="new-goal" class="mr-sm-2">
                            <b-input type="number" id="new-goal" v-model="newGoal"></b-input>
                        </b-form-group>

                        <b-form-group label="Objectif total" label-for="total-goal">
                            <p class="mt-1">{{ actualGoal }}</p>
                        </b-form-group>
                    </div>
                    <div v-else class="row ml-1">
                        <b-form-group label="Budget à réaffecter : " label-for="new-budget" class="mr-5">
                            <p class="mt-1">{{ Math.round(mediaInfo.details.unit_price_excluding_taxes) }} €</p>
                        </b-form-group>

                        <b-form-group label="Budget total à réaffecté : " label-for="total-budget" class="ml-5">
                            <p class="mt-1">{{ actualGoal }}</p>
                        </b-form-group>
                    </div>
                </div>
            </b-form>

            <div class="d-flex justify-content-between">
                <b-button class="mt-3" @click="cancelLeverModal()">Annuler</b-button>
                <b-button class="mt-3" variant="primary" :disabled="disabledLeverBtn" @click="updateCampaign()">Confirmer</b-button>
            </div>
        </b-modal>

        <generic-confirm-modal ref="modal-media-link" title="Modifier l’ID du média" cancel-title="Annuler" ok-title="Modifier" @on-accept="onSubmit" @on-cancel="$refs['modal-media-link'].close()">
            <b-form @submit.stop.prevent="">
                <b-form-group :label="`Marque`" label-for="select-brand">
                    <b-input id="select-brand" v-model="brand" disabled></b-input>
                </b-form-group>
                <b-form-group :label="`Point de vente`" label-for="select-pos">
                    <b-input id="select-pos" v-model="salePoint" disabled></b-input>
                </b-form-group>
                <b-form-group :label="`Code de point de vente`" label-for="select-pos-code">
                    <b-input id="select-pos-code" v-model="salePointCode" disabled></b-input>
                </b-form-group>
                <b-form-group :label="`Média categorie`" label-for="select-media">
                    <b-input id="select-media" v-model="media" disabled></b-input>
                </b-form-group>
                <b-form-group label="DSP" label-for="select-dsp">
                    <b-form-select id="select-dsp" v-model="selectedDsp" :options="dspOptions" text-field="name" value-field="value" :state="isValidDsp"></b-form-select>
                    <b-form-invalid-feedback :state="isValidDsp">Un DSP doit être sélectionné</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="ID" label-for="select-id">
                    <b-input id="selecte-id" v-model="id" :state="isValidId" />
                    <b-form-invalid-feedback :state="isValidId">Une ID doit être saisie</b-form-invalid-feedback>
                </b-form-group>
            </b-form>
        </generic-confirm-modal>

        <generic-confirm-modal
            ref="modal-global-alerts"
            :title="`${resolvedAlerts.length} ${alertLabel}`"
            @on-accept=""
            @ok-disabled="true"
            @on-cancel="$refs['modal-global-alerts'].close()"
            :cancel-disabled="true"
            :hideFooter="true"
            size="lg"
        >
            <div style="overflow-y: auto">
                <div v-for="campAlert in resolvedAlerts" class="modal-alerts">
                    <div style="width: 100%; min-width: 0; display: flex; gap: 10px; align-items: center; padding: 1rem 0">
                        <div v-if="campAlert.icon && !campAlert.details.isParent">
                            <SvgIcon :name="campAlert.icon" style="width: 20px" />
                        </div>

                        <div v-if="!campAlert.details.isParent" class="alert-container">
                            <div class="alert-body">
                                <span>{{ campAlert.details.point_code + ` - ` ? campAlert.details.point_code : '' }}</span>
                                <span class="data-name">{{ campAlert.details.city ? campAlert.details.city : '' }}</span> -
                                <span class="alert-media-name">{{ campAlert.details.name ? campAlert.details.name : '' }}</span>
                            </div>
                            <div class="alert-group-name">
                                <span>{{ campAlert.details.financialGroupName ? campAlert.details.financialGroupName : '' }}</span>
                            </div>
                        </div>

                        <div v-else class="alert-container">
                            <div class="alert-body">
                                <span>{{ campAlert.details.point_code ? campAlert.details.point_code[0] + ` - ` : '' }}</span>
                                <span class="data-name">{{ campAlert.details.city ? campAlert.details.city : '' }}</span> -
                                <span class="alert-media-name">{{ campAlert.details.label_quotation ? campAlert.details.label_quotation : '' }}</span>
                            </div>
                            <div class="alert-group-name">
                                <span>{{ campAlert.details.financialGroupName ? campAlert.details.financialGroupName : '' }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="campAlert.brandIcon && campAlert.code !== 999 && campAlert.type !== 'globalAlert'" style="display: flex; justify-content: end">
                        <SvgIcon :name="campAlert.brandIcon" style="width: 24px" />
                    </div>

                    <b-button
                        v-if="campAlert.type === 'top_10'"
                        variant="secondary"
                        size="sm"
                        :disabled="campAlert.resolved === 'pending' || campAlert.resolved === 'error'"
                        @click="completeMetrics(campAlert)"
                        class="complete-metrics-btn"
                        :class="{ 'error-btn': campAlert.resolved === 'error' }"
                    >
                        {{ campAlert.resolved === 'ready' ? 'Compléter' : campAlert.resolved === 'pending' ? 'En cours...' : 'Erreur' }}

                        <div v-if="campAlert.resolved === 'error'" class="svg-container">
                            <img src="@/assets/images/icons/info-icon.svg" alt="Errored" style="width: 16px; height: 17px" />
                        </div>
                    </b-button>
                </div>
            </div>
        </generic-confirm-modal>

        <copyZipCodeModal
            v-if="modalShow"
            :modalTitle="modalTitle"
            :selectedFormat.sync="selectedFormat"
            @show-toast="handleToast"
            :pointCode="pointCodeForZipCodes"
            :brand="brandForZipCode"
            @close="modalShow = false"
        />
    </div>
</template>

<script>
    import { nextTick } from '@vue/composition-api';
    import RadialBarChart from '../../helpers/RadialBarChart.vue';
    import SvgIcon from '@/partials/svg.vue';
    import store from '@/store';
    import { BTable, BFormInput, BCollapse } from 'bootstrap-vue';
    import { mapGetters, mapActions } from 'vuex';
    import axios from 'axios';
    import http from '@/helpers/http';
    import FileSaver from 'file-saver';
    import copyZipCodeModal from '@/components/copyZipCodeModal.vue';

    const moment = require('moment');

    export default {
        components: {
            RadialBarChart,
            SvgIcon,
            BTable,
            BFormInput,
            BCollapse,
            copyZipCodeModal,
        },

        props: {
            parentSortBy: {
                type: String,
                required: true,
            },
            searchFilter: {
                type: String,
                required: true,
            },
            tables: {
                type: Object,
                required: true,
            },
            currentTable: {
                type: Number,
                required: true,
            },
            statusFilters: {
                type: Array,
                required: true,
            },
            statusSubFilters: {
                type: Array,
                required: true,
            },
            filterByMedia: {
                type: Array,
                required: true,
            },
            filterByBrand: {
                type: Array,
                required: true,
            },
            campaigns: {
                type: Array,
                required: true,
            },
            medias: {
                type: Array,
                required: true,
            },
            alerts: {
                type: Array,
                required: true,
            },
            currentTableFields: {
                type: Array,
                required: true,
            },
            perPage: {
                type: Number,
                required: true,
            },
            currentPage: {
                type: Number,
                required: true,
            },
            right: {
                type: String,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                sortDesc: true,
                detailsRow: null,
                isCollapsed: false,
                rows: 0,
                borderless: true,
                sortBy: 'startDateMedia',
                mediaFields: [
                    {
                        key: 'mediaName',
                        label: 'Média',
                        thStyle: { width: '25%' },
                        class: 'media-content',
                    },
                    {
                        key: 'label',
                        label: 'Status',
                        thStyle: { width: '11%', textAlign: 'right' },
                    },
                    {
                        key: 'startDate',
                        label: 'date de début',
                        thStyle: { width: '16%', textAlign: 'right' },
                    },

                    {
                        key: 'endDate',
                        label: 'date de fin',
                        thStyle: { width: '12%', textAlign: 'right' },
                    },
                    {
                        key: 'metricsDays',
                        label: 'metrics / days',
                        thStyle: { width: '9%', textAlign: 'right' },
                    },
                    {
                        key: 'metrics',
                        label: 'metrics',
                        class: 'objective-cell',
                        thStyle: { width: '16%', textAlign: 'right' },
                    },
                    {
                        key: 'actionsMedia',
                        label: 'actions',
                        thStyle: { width: '11%', textAlign: 'right' },
                    },
                ],

                selectedCampaign: null,
                brand: null,
                media: null,
                salePoint: null,
                salePointCode: null,
                alertType: '',
                selectedDsp: '',
                dspOptions: [],
                isValidDsp: null,
                id: '',
                isValidId: null,
                renderErrors: false,
                reassignLever: false,
                selected: null,
                newGoal: 0,
                isPackage: false,
                goal: '',
                validMedia: process.env.VUE_APP_TTD_VALID_MEDIA ? process.env.VUE_APP_TTD_VALID_MEDIA.split(',') : '',
                facebookMedia: ['FBF', 'FBK'],
                validMediaNoUrl: process.env.VUE_APP_TTD_VALID_MEDIA_NO_URL ? process.env.VUE_APP_TTD_VALID_MEDIA_NO_URL.split(',') : '',
                media: '',
                mediaInfo: {},
                alertLabel: '',
                alertMediaCode: '',
                campaignsAlerts: [],
                zipcode: [],
                selectedFormat: 'comma',
                selectedPos: {},
                modalShow: false,
                modalTitle: 'Details Geographiques',
                pointCodeForZipCodes: '',
                brandForZipCode: '',
            };
        },

        computed: {
            ...mapGetters({
                formatToDate: 'app/formatToDate',
                formatToDashDate: 'app/formatToDashDate',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
                allCampaigns: 'app/getCurrentCampignsList',
                globalAlerts: 'app/getGlobalAlerts',
                alertsList: 'app/getCurrentAlertsList',
            }),

            resolvedAlerts() {
                const commonAlerts = [];
                for (const alert of this.globalAlerts) {
                    if (this.campaignsAlerts.find((campaignAlert) => campaignAlert.code === alert.code)) {
                        commonAlerts.push(alert);
                    }
                }
                return commonAlerts;
            },

            currentTableData() {
                const formattedData = JSON.parse(JSON.stringify(this.tables));

                let currentTableData = formattedData[this.currentTable].data;

                if (this.statusFilters.includes('8') && this.statusSubFilters.length > 0) {
                    currentTableData = currentTableData.filter((item) => item.status !== 8 || this.statusSubFilters.includes(item.value));
                }

                if (this.filterByMedia.length > 0) {
                    const lowercasedSort = this.filterByBrand.map((media) => media?.toLowerCase());

                    currentTableData = currentTableData.filter(
                        (media) => this.filterByMedia.includes(media.details.media_label) && (this.filterByBrand || lowercasedSort.includes(media.details.brand_label.toLowerCase()))
                    );
                }

                if (this.filterByBrand.length > 0) {
                    const lowercasedSort = this.filterByBrand.map((media) => media.toLowerCase());

                    currentTableData = currentTableData.filter((media) =>
                        media.is_parent === 1 ? lowercasedSort.includes(media?.brand_label?.toLowerCase()) : lowercasedSort.includes(media?.details?.brand_label?.toLowerCase())
                    );
                }

                if (this.searchFilter !== '') {
                    const searchFilterLower = this.searchFilter.toLowerCase();

                    currentTableData = currentTableData.filter((media) => {
                        return Object.values(media).some((value) => String(value).toLowerCase().includes(searchFilterLower));
                    });
                }

                if (
                    this.filterByMedia.length > 0 &&
                    this.campaigns &&
                    (this.statusFilters.includes('7') || this.statusFilters.includes('8') || this.statusFilters.includes('10') || this.statusFilters.includes('20'))
                ) {
                    for (const campaign of this.campaigns) {
                        if (campaign.details.lines) {
                            const hasMatchingLine = campaign.details.lines.some((line) => this.filterByMedia.includes(line.media_category));

                            if (hasMatchingLine) {
                                currentTableData.push(campaign);
                            }
                        }
                    }
                }

                if (this.media) {
                    const mediametrics = this.$store.state.app.mediaMetrics;
                    for (const media of mediametrics) {
                        if (media.media === this.media) {
                            this.media = media.label;
                        }
                    }
                }

                if (this.currentTable === 3) {
                    currentTableData.sort((a, b) => {
                        if (a?.isDanger && !b?.isDanger) {
                            return -1;
                        } else if (!a?.isDanger && b?.isDanger) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                }

                this.setRows(currentTableData);
                return currentTableData;
            },

            disabledLeverBtn() {
                if (this.reassignLever) {
                    return this.isPackage ? !this.selected : !(this.selected && this.newGoal);
                } else {
                    return false;
                }
            },
            leversOptions() {
                return [
                    {
                        label: 'Levier(s) de la campagne : ',
                        options: this.childOptions,
                    },
                    {
                        label: 'Autres leviers : ',
                        options: this.otherOptions,
                    },
                ];
            },
            childOptions() {
                let childArray = [];

                const campInfo = this.allCampaigns.find((camp) => camp.campaign_code === this.campaignCode);
                const children = campInfo.details.children;
                const mediametrics = this.$store.state.app.mediaMetrics;

                for (const child of children) {
                    if (child.media_support_code !== this.mediaInfo.media_support_code) {
                        childArray.push({
                            text: child.media_support_code,
                            value: child.media_support_code,
                        });
                    }
                }

                for (const media of mediametrics) {
                    for (const currentMedia of childArray) {
                        if (media.media === currentMedia.value) {
                            currentMedia.text = media.label;
                        }
                    }

                    if (media.media === this.media) {
                        this.media = media.label;
                    }
                }

                return childArray;
            },
            otherOptions() {
                return this.$store.state.app.mediaMetrics
                    .filter((child) => {
                        const mediaMetric = this.childOptions.find((metric) => metric.value === child.media);
                        return !mediaMetric;
                    })
                    .map((child) => ({
                        text: child.label,
                        value: child.media,
                    }));
            },
            actualGoal() {
                if (this.selected) {
                    const campInfo = this.allCampaigns.find((camp) => camp.campaign_code === this.campaignCode);
                    const children = campInfo.details.children;
                    const selectedLever = children.find((el) => el.media_support_code === this.selected);

                    let mediaMetrics = this.getMediaMetrics(this.selected);
                    let geolink = JSON.parse(mediaMetrics.geolinkDetails);
                    this.isPackage = geolink.isPackage ? true : false;
                    if (selectedLever) {
                        this.goal = selectedLever.goal;
                        if (geolink.isPackage) {
                            return Math.round(Number(selectedLever.details.unit_price_excluding_taxes)) + Math.round(Number(this.mediaInfo.details.unit_price_excluding_taxes)) + ' €';
                        } else {
                            const totalGoal = Number(selectedLever.details.quantity) + Number(this.newGoal);
                            return totalGoal + ' ' + this.goal;
                        }
                    } else {
                        if (geolink.isPackage) {
                            return Math.round(Number(this.mediaInfo.details.unit_price_excluding_taxes)) + ' €';
                        } else {
                            this.goal = geolink.goal;
                            return this.newGoal ? Math.round(Number(this.newGoal)) + ' ' + this.goal : '-- ' + this.goal;
                        }
                    }
                }
            },
        },

        methods: {
            ...mapActions('app', ['getAvailableMedias', 'removeChildCampaign', 'changeChildCampaign', 'transferChildCampaign']),

            setRows(list) {
                this.rows = list.length;

                this.$emit('tableDataLength', this.rows);
            },

            getAlertColor(media) {
                const goal = media.goal;
                const isPackage = media.details.package;

                if (goal === 'impressions') {
                    return this.defineAlertColor(isPackage, media.details?.sum_of_impressions, media.ratio, media.ratioAlert).color;
                } else if (goal === 'video_quartile_p100') {
                    return this.defineAlertColor(isPackage, media.details?.sum_of_views, media.ratio, media.ratioAlert).color;
                } else if (goal === 'clicks') {
                    return this.defineAlertColor(isPackage, media.details?.sum_of_clicks, media.ratio, media.ratioAlert).color;
                }
                return '#93909d';
            },

            defineAlertColor(isPackage, quantity, objectifRatio, ratioAlert) {
                const gradientToColors = {
                    success: '#28C76F',
                    warning: '#FF9F43',
                    danger: '#F63B51',
                    default: '#93909d',
                };

                let color;

                if (ratioAlert) {
                    if (ratioAlert === 'vigilance') {
                        color = gradientToColors.warning;
                    } else if (ratioAlert === 'critical') {
                        color = gradientToColors.danger;
                    } else {
                        color = gradientToColors.default;
                    }
                } else {
                    color = objectifRatio === 0 ? gradientToColors.default : objectifRatio < 5 ? gradientToColors.danger : objectifRatio <= 15 ? gradientToColors.warning : gradientToColors.default;
                }

                return {
                    value: isPackage ? quantity?.toFixed(2).toLocaleString('fr-FR') : quantity?.toLocaleString('fr-FR'),
                    color: color,
                };
            },

            async openZipCodeModal(brand, pointCode) {
                this.brandForZipCode = brand;
                this.pointCodeForZipCodes = pointCode;
                this.modalShow = !this.modalShow;
            },

            async handleToast({ type, message }) {
                this.$toast(this[type + 'Toast'](message));
            },

            async deleteAlert(alertCode) {
                this.confirmDeleteAlert(alertCode);
            },

            async confirmDeleteAlert(alertCode) {
                try {
                    if (!alertCode) {
                        throw new Error("Le code d'alerte est requis.");
                    }

                    const confirmationMessage = 'Êtes-vous sûr de vouloir supprimer cette alerte ?';

                    const modalConfig = {
                        title: 'Supprimer une alerte',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'primary',
                        okTitle: 'OUI',
                        cancelTitle: 'NON',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                    };

                    const confirmed = await this.$bvModal.msgBoxConfirm(confirmationMessage, modalConfig);

                    if (confirmed) {
                        await this.$store.dispatch('app/deleteAlert', { code: alertCode });

                        this.$toast(this.successToast("L'alerte a été supprimée avec succès"));
                        this.$emit('refresh');
                    }
                } catch (error) {
                    console.error(error);
                    this.$bvToast.toast("Une erreur s'est produite lors de la suppression de l'alerte.", {
                        title: 'Erreur',
                        variant: 'danger',
                        autoHideDelay: 5000,
                    });
                }
            },

            formatDate(dateString) {
                const date = new Date(dateString);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            },

            formatDateAlert(dateString) {
                if (!dateString) return '';
                const parts = dateString.split('/');
                if (parts.length !== 3) return dateString;
                return parts[0] + '-' + parts[1] + '-' + parts[2];
            },

            getMediaMetrics(mediaCode) {
                return this.$store.state.app.mediaMetrics.find((m) => m.media === mediaCode);
            },

            cancelLeverModal() {
                this.reassignLever = false;
                this.selected = null;
                this.$refs['modal-update-campaign-lever'].hide();
            },

            async openReplaceMediaModal(item, isAlert) {
                this.media = item.media_support_code ? item.media_support_code : item.details.media_category ? item.details.media_category : '';
                this.mediaInfo = item;
                this.campaignCode = item.parent;

                if (isAlert) {
                    this.alertMediaCode = item.code;
                }
                this.$refs['modal-update-campaign-lever'].show(item);
            },

            async completeMetrics(camp) {
                if (!camp || typeof camp !== 'object' || !camp.type || !camp.campaign_code) {
                    console.error('Campagne invalide :', camp);
                    return;
                }

                this.$root.toggleGlobalLoading(true);

                const campCode = camp.campaign_code;
                const alertCode = camp.code;

                if (camp.type === 'top_10') {
                    try {
                        await this.$store.dispatch('app/completeMetrics', campCode);

                        await this.$store.dispatch('app/updateAlertResolved', {
                            code: alertCode,
                            data: { resolvedStatus: 'pending' },
                        });

                        await this.$store.dispatch('app/updateStatusAlert', {
                            code: alertCode,
                            status: 'pending',
                        });

                        this.$toast(this.successToast('Demande de mise à jour des métriques du TopTen lancée'));
                    } catch (error) {
                        console.error('Erreur lors de la mise à jour des top ten :', error);
                        this.$toast(this.errorToast('Erreur lors de la mise à jour des top ten'));
                    } finally {
                        this.$root.toggleGlobalLoading(false);
                    }
                }
            },

            onSubmit() {
                if (!this.selectedDsp) {
                    this.isValidDsp = false;
                    return;
                }
                if (!this.id) {
                    this.isValidId = false;
                    return;
                }

                this.openAlert();
            },

            openAlert() {
                this.$bvModal
                    .msgBoxConfirm("Êtes-vous sûr de vouloir modifier l'id du média ?", {
                        title: 'Confirmer',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'primary',
                        okTitle: 'OUI',
                        cancelTitle: 'NON',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((confirmed) => {
                        if (confirmed) {
                            this.linkCampaign(this.selectedCampaign, this.media, this.selectedDsp, this.id, this.alertType);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },

            async openGlobalAlertsModal(campaigns) {
                let subfilters = [];

                this.alertLabel = campaigns.modalTopTenLabel ? campaigns.modalTopTenLabel : campaigns.label;
                this.campaignsAlerts = campaigns.alerts;

                switch (campaigns.label) {
                    case 'Bilan en retard':
                    case 'Bilans en retard':
                        this.$emit('updateStatusFilters', ['7']);
                        break;

                    case 'Validation CSM en retard':
                    case 'Validations CSM en retard':
                        this.$emit('updateStatusFilters', ['8']);
                        subfilters = ['csm'];
                        await this.$store.dispatch('app/updateStatusSubFilter', subfilters);
                        break;

                    case 'Validation Direction en retard':
                    case 'Validations Direction en retard':
                        this.$emit('updateStatusFilters', ['8']);
                        subfilters = ['direction'];
                        await this.$store.dispatch('app/updateStatusSubFilter', subfilters);
                        break;

                    default:
                        this.$refs['modal-global-alerts'].open(this.alertLabel, this.resolvedAlerts);
                        break;
                }

                // Scroll to top of the page
                window.scrollTo(0, 0);
            },

            async downloadReport(data) {
                const campaignCode = data.campaign_code;

                const pointText = data.details.point_text[0].replaceAll(' ', '-');
                const startDate = data.start_date.replaceAll('/', '-');
                const brand = campaignCode.split('_')[1].toLowerCase();

                this.$root.toggleGlobalLoading(true);
                http.getPDF(this.$store.state.app.apiCampaignUrl + '/api/v1/' + brand + '/campaign/' + campaignCode + '/metrics', localStorage.getItem('accessToken'))
                    .then((res) => {
                        this.$root.toggleGlobalLoading(false);
                        if (res[0].data.byteLength > 0) {
                            const blob = new Blob([res[0].data], {
                                type: res[0].headers['content-type'],
                            });

                            FileSaver.saveAs(blob, pointText + '_' + startDate + '_bilan.pdf');
                            this.$toast({
                                component: 'toast',
                                props: {
                                    title: 'Le rapport a été téléchargé',
                                    icon: 'smile',
                                    variant: 'success',
                                },
                            });
                        } else {
                            this.$toast({
                                component: 'toast',
                                props: {
                                    title: 'Pas de rapport à télécharger',
                                    icon: 'x-circle',
                                    variant: 'danger',
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Erreur lors de la génération du rapport',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    });
            },

            async linkCampaign(campaign, media, dsp, id, type) {
                try {
                    this.$root.toggleGlobalLoading(true);

                    const data = {
                        provider: dsp,
                        media,
                        id,
                    };

                    const res = await this.$store.dispatch('app/linkCampaign', {
                        code: campaign,
                        data,
                    });

                    this.$refs['modal-media-link'].close();

                    this.$toast(this.successToast("L'id du média a été modifié avec succès"));

                    if (this.alertType === 'unlink') {
                        await this.$store.dispatch('app/deleteAlert', {
                            code: code,
                        });
                    }

                    this.$emit('refresh');
                } catch (error) {
                    this.$toast(this.errorToast("L'id du média n'a pas pu être modifié"));
                } finally {
                    this.$root.toggleGlobalLoading(false);
                }
            },

            async modifyProviderId(media) {
                const dsp = await axios.get(process.env.VUE_APP_APIURL + '/metrics/api/dspProviders/');
                this.dspOptions = dsp.data;

                this.selectedCampaign = media.campaign_code;
                this.brand = media.brand;
                this.media = media.media_support_code;
                this.salePoint = media.city;
                this.salePointCode = media.point_code;
                this.id = media.details.provider_data.id;
                this.selectedDsp = media.details.provider;

                this.$refs['modal-media-link'].open();
            },

            async updateCampaign() {
                try {
                    if (this.alertMediaCode) {
                        await this.$store.dispatch('app/deleteAlert', {
                            code: this.alertMediaCode,
                        });
                    }

                    if (!this.reassignLever) {
                        await this.removeChildCampaign({
                            code: this.mediaInfo.campaign_code,
                            mediaCode: this.mediaInfo.media_support_code ? this.mediaInfo.media_support_code : this.mediaInfo.details.media_category,
                        });
                    } else {
                        let child = this.childOptions.find((child) => child.value === this.selected);
                        let data = {
                            goal: this.isPackage ? '' : this.actualGoal.split(' ')[0],
                            type_goal: this.isPackage ? '' : this.goal,
                        };
                        let params = {
                            code: this.mediaInfo.campaign_code,
                            mediaCode: this.mediaInfo.media_support_code ? this.mediaInfo.media_support_code : this.mediaInfo.details.media_category ? this.mediaInfo.details.media_category : '',
                            newMediaCode: this.selected,
                        };
                        if (child) {
                            await this.transferChildCampaign({ params, data });
                        } else {
                            await this.changeChildCampaign({ params, data });
                        }
                    }
                    this.$emit('updateChildCampaign');

                    this.$toast(this.successToast('Mise à jour de la campaign réussie'));
                    this.$refs['modal-update-campaign-lever'].hide();
                } catch (error) {
                    console.log(error);
                    this.$toast(this.errorToast('Mise à jour de la campaign échouée'));
                }
            },

            async toggleRow(row) {
                if (row.status >= 7 && row.details.children) {
                    if (this.detailsRow && this.detailsRow !== row) {
                        this.detailsRow._showDetails = false;
                        this.isCollapsed = false;
                    }

                    if (this.detailsRow === row) {
                        this.isCollapsed = false;
                    }

                    if (row._showDetails === false) {
                        document.body.style.cursor = 'wait';
                        document.querySelector('table').style.pointerEvents = 'none';

                        try {
                            let res = await this.$store.dispatch('app/getCampaignInfos', {
                                code: row.campaignCode,
                                status: row.status,
                            });

                            let resCampaign = res.data;

                            this.globalMedias = [];
                            this.globalTopTen = [];
                            this.globalDone = [];

                            for (let i = 0; i < resCampaign.length; i++) {
                                if (row.campaignCode !== resCampaign[i].campaignCode) {
                                    nextTick();
                                }
                                this.globalMedias.push({
                                    status: resCampaign[i].status,
                                    media: resCampaign[i].media_support_code,
                                    label: resCampaign[i].label,
                                    startDate: resCampaign[i].start_date,
                                    endDate: resCampaign[i].end_date,
                                    endDateMedia: resCampaign[i].end_date,
                                    provider: resCampaign[i].details?.provider_data?.id,
                                });
                                this.globalTopTen.push(resCampaign[i].infos);
                                this.globalDone.push(resCampaign[i].done);
                            }
                            this.isCollapsed = true;

                            row.globalMedias = this.globalMedias;
                        } catch (e) {
                            console.error(e);
                        } finally {
                            document.body.style.cursor = '';
                            document.querySelector('table').style.pointerEvents = '';
                        }
                    }
                    row._showDetails = !row._showDetails;
                    this.detailsRow = row;
                }
            },

            calculateDays(start, end) {
                if (!moment(start).isValid() || !moment(end).isValid()) {
                    console.error('Les dates fournies ne sont pas valides.');
                    return null;
                }

                try {
                    const startDate = moment(start);
                    const endDate = moment(end);

                    const daysDifference = endDate.diff(startDate, 'days');

                    const daysDifferenceInclusive = daysDifference + 1;

                    return daysDifferenceInclusive;
                } catch (error) {
                    console.error('Une erreur est survenue lors du calcul des jours :', error);
                    return null;
                }
            },
            seeDetails(code, cible) {
                store.commit('app/UPDATE_CURRENT_CAMPAIGN_CODE', code);
                this.$router.push({
                    name: 'details',
                    hash: cible ? `#${cible.toLowerCase()}` : undefined,
                });
            },

            copyMediaId(mediaId) {
                try {
                    if (mediaId === '') {
                        console.error("Ce media n'a pas d'ID");
                    } else {
                        navigator.clipboard.writeText(mediaId);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: "L'ID du média a bien été copié.",
                                icon: 'CheckCircleIcon',
                                variant: 'success',
                            },
                        });
                    }
                } catch (err) {
                    console.error('Erreur lors de la copie dans le presse-papiers : ', err);
                }
            },

            async fillWithZeroMetrics(code, isAlert, alertCode) {
                try {
                    this.$root.toggleGlobalLoading(true);
                    await this.$store.dispatch('app/fillWithZeroMetrics', { code });

                    if (isAlert && alertCode) {
                        await this.$store.dispatch('app/deleteAlert', {
                            code: alertCode,
                        });
                    }
                    this.$toast(this.successToast('Mise à jour des metrics effectuée'));
                } catch (error) {
                    console.error(error);
                    this.$toast(this.errorToast('Erreur lors de la mise à jour des metrics'));
                } finally {
                    this.$root.toggleGlobalLoading(false);
                }
            },

            async completeTopTen(code) {
                try {
                    this.$root.toggleGlobalLoading(true);
                    await this.$store.dispatch('app/completeTopTen', { code });
                    this.$toast(this.successToast('Top ten mis à jour'));
                } catch (error) {
                    console.error(error);
                    this.$toast(this.errorToast('Erreur lors de la mise à jour des top ten'));
                } finally {
                    this.$root.toggleGlobalLoading(false);
                }
            },

            bannerDownloadMedia(media) {
                this.$root.toggleGlobalLoading(true);
                let data = {
                    code: media.parent ? media.parent : '',
                    pointCode: media.point_code ? media.point_code : '',
                    theme: '',
                };

                this.$store.dispatch('app/downloadBanner', data).then((res) => {
                    this.$root.toggleGlobalLoading(false);

                    if (res.data.length > 0) {
                        for (const url of res.data) {
                            const fileLink = document.createElement('a');
                            fileLink.href = url;
                            fileLink.target = '_blank';
                            fileLink.setAttribute('download', media.pointText);
                            document.body.appendChild(fileLink);
                            fileLink.click();
                        }

                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'La bannière a été téléchargée',
                                icon: 'CheckCircleIcon',
                                variant: 'success',
                            },
                        });
                    } else {
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Pas de bannière à télécharger',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    }
                });
            },

            tradingRoute(code) {
                this.$router.push({
                    name: 'trading',
                    params: {
                        campaign_code: code,
                    },
                });
            },
        },

        watch: {
            parentSortBy: {
                immediate: true,
                handler(newValue) {
                    this.sortBy = newValue;
                },
            },
        },
        async mounted() {
            await this.getAvailableMedias();
        },
    };
</script>

<style lang="scss">
    [dir] .table.b-table > tbody > tr.b-table-details > td {
        padding-right: 0 !important;
    }

    .btn.btn-link {
        padding: 0 !important;
    }

    .cell-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .main-element {
        font-size: 13px;
        font-weight: 500;

        &.bold {
            font-weight: 700;
        }
    }

    .secondary-element {
        font-size: 11px;
        color: rgb(147, 144, 157);
    }

    .unlink-container {
        display: flex;
        gap: 10px;
        background: #f63b5129;
        padding: 6px 10px;
        height: 28px;
        color: #f63b51;
        font-weight: 600;
        border-radius: 4px;
        align-items: center;
        width: 10rem;
    }

    .danger {
        color: #f63b51;
    }

    .vigilant {
        color: #ff9f43;
    }

    .alerts-container {
        tbody {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        tr {
            height: 52px;
            display: flex;
            align-items: center;
            border-right: 4px !important;
            border-radius: 6px !important;
            justify-content: space-between;
            background-color: white;

            &:has(.is-danger) {
                border-left: 4px solid #f63b51 !important;

                &:has(.is-last) {
                    margin-bottom: 1rem !important;
                }
            }
            &:has(.is-warning) {
                border-left: 4px solid #ff9f43 !important;
            }

            td {
                &.media-content {
                    width: 25%;
                    height: 80px !important;
                }
                &.objective-cell {
                    width: 10%;
                    justify-content: flex-end;
                }
                &.actions-cell {
                    width: 5%;
                }

                * {
                    justify-content: center;
                }
            }
        }
    }

    .dark-layout .alerts-container tr {
        background-color: #242b3d;
    }

    .dark-layout {
        .alerts-container {
            tbody {
                background-color: #161d31;
            }
        }
    }

    .display-none {
        display: none !important;
    }

    .modal-alerts {
        display: flex;
        align-items: center;
        border-top: 1px solid #dbdade;
        gap: 15px;
        justify-content: space-between;

        .alert-container {
            min-width: 0;
        }

        .alert-body {
            display: flex;
            justify-content: flex-start;
            gap: 5px;
            font-size: 13px;
            font-weight: 600;
            line-height: 22px;
        }

        .alert-media-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .alert-group-name {
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            color: #93909d;
        }

        .complete-metrics-btn {
            font-size: 13px;
            font-weight: 500;
            line-height: 15px;
            white-space: nowrap;
            margin-right: 1.5rem;
        }

        .error-btn {
            background-color: #f63b51 !important;
            border: none;
        }

        .svg-container {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .row-details tbody {
        background-color: #f6f6f9;
    }

    .dark-layout .row-details tbody {
        background-color: #242b3d;
    }
</style>
