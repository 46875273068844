<template>
    <b-modal v-model="show" :title="modalTitle" :hide-footer="true">
        <p>URL des bannières des campagnes enfants :</p>
        <b-overlay :show="fetchingCampaignChildren">
            <p v-if="isCampaignStartDateToday" class="warning">⚠️ Vous êtes sur le point de lancer une campagne pour aujourd'hui</p>

            <b-form-group v-for="camp in validChildrenCampaign" :key="camp.campaign_code">
                <label class="campChildTitle">Campagne {{ camp.media_support_code }}</label>
                <div class="modifyCamp">
                    <label> Account </label>
                    <b-form-input v-model="account" type="text" aria-describedby="input-live-help input-live-feedback" autofocus placeholder="Account Id" />
                </div>

                <div class="modifyCamp">
                    <label> PageId </label>
                    <b-form-input v-model="pageId" type="text" aria-describedby="input-live-help input-live-feedback" autofocus placeholder="Page Id" />
                </div>

                <div class="modifyCamp">
                    <label> Audience </label>
                    <b-form-input v-model="audience" type="text" aria-describedby="input-live-help input-live-feedback" autofocus placeholder="Audience Id" />
                </div>

                <div class="modifyCamp">
                    <label> Titre </label>
                    <b-form-input v-model="title" type="text" aria-describedby="input-live-help input-live-feedback" autofocus placeholder="Titre" />
                </div>
                <div class="modifyCamp">
                    <label> Description </label>
                    <b-form-input v-model="description" type="text" aria-describedby="input-live-help input-live-feedback" autofocus placeholder="Description" />
                </div>

                <div class="modifyCamp">
                    <label :for="camp.media_support_code"> Url de la bannière </label>
                    <b-form-input
                        v-if="facebookMedia.includes(camp.media_support_code)"
                        v-model="creativesUrl[camp.campaign_code]"
                        :id="camp.campaign_code"
                        type="url"
                        :state="creativeUrlState[camp.campaign_code]"
                        aria-describedby="input-live-help input-live-feedback"
                        autofocus
                        required
                        placeholder="https://adcleek.com/"
                    />
                    <p v-else>URL non necessaire</p>
                    <b-form-invalid-feedback id="input-live-feedback"> URL incorrecte </b-form-invalid-feedback>
                </div>

                <div class="modifyCamp">
                    <label> Text </label>
                    <div v-if="creativesText[camp.campaign_code]" v-for="(text, index) in creativesText[camp.campaign_code]" :key="index">
                        <b-form-input
                            class="input-text"
                            v-model="creativesText[camp.campaign_code][index]"
                            type="text"
                            aria-describedby="input-live-help input-live-feedback"
                            autofocus
                            placeholder="Text de la campagne"
                        />
                    </div>
                    <b-btn v-if="creativesText[camp.campaign_code] && creativesText[camp.campaign_code].length < 3" @click="addTextInput(camp.campaign_code)">+</b-btn>
                </div>

                <b-btn
                    style="margin-top: 20px"
                    id="btnCreateCampaignProvider"
                    v-if="camp.details && !camp.details.creaData"
                    :disabled="disableCreateStatus[camp.campaign_code]"
                    @click="createProviderCampaign(camp.campaign_code)"
                    variant="primary"
                >
                    Créer
                </b-btn>
            </b-form-group>

            <br />
            <b-btn id="btnCreateCampaignProvider" v-if="showGlobalCreateStatus" :disabled="disableGlobalCreateStatus" @click="createProviderCampaign()" variant="primary">Créer</b-btn>
        </b-overlay>
    </b-modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        data() {
            return {
                btnCreateCampaignProviderState: false,
                description: '',
                title: '',
                creativesUrl: {},
                creativesText: {},
                creativeUrlStatus: true,
                childrenCampaign: [],
                show: false,
                fetchingCampaignChildren: true,
                campMedias: [],
                facebookMedia: ['FBF', 'FBK'],
                creas: [],
                accounts: [],
                audiences: [],
                account: '',
                audience: '',
                creaOptions: [],
                creaSelected: {},
                accountSelected: {},
                pageIdSelected: {},
                parentCampaign: {},
                showCreateStatus: {},
                showGlobalCreateStatus: false,
                validCamp: {},
                pageId: '',
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),

            modalTitle() {
                const firstValidChildCampaign = this.validChildrenCampaign[0];
                const details = firstValidChildCampaign && firstValidChildCampaign.details;
                const creaData = details && details.creaData;
                const code = creaData && creaData.code;
                return code ? 'Modifier le média chez facebook' : 'Créer le média chez facebook';
            },

            isCampaignStartDateToday() {
                const today = new Date().toISOString().slice(0, 10);
                return this.validChildrenCampaign.some((camp) => camp.start_date === today);
            },

            creativeUrlState() {
                let creativeUrlState = {};

                this.creativeUrlStatus = Object.keys(this.creativesUrl).length > 0 ? true : false;
                return creativeUrlState;
            },

            disableCreateStatus() {
                let disableCreateStatus = {};
                this.childrenCampaign.forEach((childCamp) => {
                    const creaInBase =
                        childCamp.details.creaData && childCamp.details.creaData.code
                            ? childCamp.details.creaData.code
                            : this.parentCampaign?.details?.crea_code
                            ? this.parentCampaign.details.crea_code
                            : '';
                    const creaUrlInBase = childCamp.details.creaData && childCamp.details.creaData.url ? childCamp.details.creaData.url : '';

                    if (this.creaSelected[childCamp.campaign_code] != creaInBase || (this.creativesUrl[childCamp.campaign_code] != creaUrlInBase && this.creativeUrlState[childCamp.campaign_code]))
                        disableCreateStatus[childCamp.campaign_code] = false;
                    else if (this.facebookMedia.includes(childCamp.media_support_code)) disableCreateStatus[childCamp.campaign_code] = false;
                    else disableCreateStatus[childCamp.campaign_code] = true;
                });

                return disableCreateStatus;
            },

            validChildrenCampaign() {
                const validChildrenCamps = this.childrenCampaign.filter((cc) => this.facebookMedia.includes(cc.media_support_code));
                return validChildrenCamps;
            },

            formatCreaOptions() {
                let creaOptions = [];
                if (this.creas.length) {
                    this.creas.forEach((c) => {
                        creaOptions.push({ item: c.crea_code, name: c.label });
                    });
                }
                return creaOptions;
            },

            disableGlobalCreateStatus() {
                let globalStatus = false;
                if (Object.keys(this.disableCreateStatus).length) {
                    const codes = Object.keys(this.disableCreateStatus);
                    globalStatus = this.disableCreateStatus[codes[0]];
                    for (const code in this.disableCreateStatus) {
                        globalStatus = globalStatus || this.disableCreateStatus[code];
                    }
                }
                return globalStatus;
            },
        },
        methods: {
            urlCheck(url) {
                const regex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
                return url.match(regex) ? true : false;
            },
            close() {
                this.creativesUrl = {};
                this.creativesText = {};
                this.show = false;
                this.fetchingCampaignChildren = true;
            },
            async open(campaignCode, childCampaignCode) {
                this.fetchingCampaignChildren = true;
                this.show = true;
                try {
                    const res = await this.$store.dispatch('app/genericGet', this.$store.state.app.apiBillingiUrl.concat('/children-campaign/?code=' + campaignCode));
                    for (const media of res.data) {
                        if (media.campaign_code === childCampaignCode) {
                            res.data = [media];
                        }
                    }
                    this.$set(this, 'childrenCampaign', res.data);

                    const creas = await this.$store.dispatch('app/getCreaByBrand', res.data[0].brand);
                    this.$set(this, 'creas', creas.data.data);

                    const parentCampRaw = await this.$store.dispatch('app/getCampaign', { campaignCode: campaignCode });
                    this.$set(this, 'parentCampaign', parentCampRaw.data);

                    const response = await this.$store.dispatch('app/getFacebookInfos', res.data);
                    const account = response.data.pages[0].info.account;
                    const pageId = response.data.pages[0].info.page_id;
                    const audience = response.data.pages[0].info.audience;
                    this.$set(this, 'account', account);
                    this.$set(this, 'pageId', pageId);
                    this.$set(this, 'audience', audience);

                    if (this.childrenCampaign.length && Object.keys(this.parentCampaign).length) {
                        this.childrenCampaign.forEach((childCamp) => {
                            const initCreaCode =
                                childCamp.details.creaData && childCamp.details.creaData.code
                                    ? childCamp.details.creaData.code
                                    : this.parentCampaign.details.crea_code
                                    ? this.parentCampaign.details.crea_code
                                    : '';
                            this.$set(this.creaSelected, childCamp.campaign_code, initCreaCode);

                            const initCreaUrl = childCamp.details.creaData && childCamp.details.creaData.url ? childCamp.details.creaData.url : '';
                            this.$set(this.creativesUrl, childCamp.campaign_code, initCreaUrl);

                            this.$set(this.creativesText, childCamp.campaign_code, ['']);

                            if (childCamp.status == 0) this.showCreateStatus[childCamp.campaign_code] = true;
                            else this.showCreateStatus[childCamp.campaign_code] = false;

                            if (this.facebookMedia.includes(childCamp.media_support_code)) this.validCamp[childCamp.campaign_code] = true;
                            else this.validCamp[childCamp.campaign_code] = false;
                        });
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    this.fetchingCampaignChildren = false;
                }
            },
            async createProviderCampaign(campCode = null) {
                this.$root.toggleGlobalLoading(true);
                try {
                    const selectedCampaigns = campCode ? this.validChildrenCampaign.filter((vcc) => vcc.campaign_code == campCode) : this.validChildrenCampaign;
                    for (const campaign of selectedCampaigns) {
                        const code = campaign.campaign_code;
                        const data = {
                            accountId: this.account ? this.account : '',
                            pageId: this.pageId ? this.pageId : '',
                            audienceId: this.audience ? this.audience : '',
                            title: this.title ? this.title : '',
                            description: this.description ? this.description : '',
                            creaCode: this.creaSelected[campaign.campaign_code] ? this.creaSelected[campaign.campaign_code] : '',
                            creativeUrl: this.creativesUrl[campaign.campaign_code] ? this.creativesUrl[campaign.campaign_code] : '',
                            creativeText: this.creativesText[campaign.campaign_code] ? this.creativesText[campaign.campaign_code] : [],
                        };
                        console.log('data', data);
                        await this.$store.dispatch('app/createFacebookCampaign', { code, data });
                    }

                    this.close();

                    this.$toast({
                        component: 'toast',
                        props: {
                            title: 'Campagnes créées avec succès',
                            icon: 'CheckCircleIcon',
                            variant: 'success',
                        },
                    });
                } catch (e) {
                    console.error(e);
                    this.$toast({
                        component: 'toast',
                        props: {
                            title: 'Erreur lors de la création des campagnes',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                } finally {
                    this.$root.toggleGlobalLoading(false);
                }
            },
            addTextInput(campaignCode) {
                if (!this.creativesText[campaignCode]) {
                    this.$set(this.creativesText, campaignCode, ['']);
                } else if (this.creativesText[campaignCode].length < 3) {
                    this.creativesText[campaignCode].push('');
                }
            },
        },
    };
</script>

<style scoped>
    .users-list-container {
        box-sizing: border-box;
        padding: 24px;
        height: 100vh;
        overflow: auto;
    }

    .users-list {
        margin: 0 auto;
        max-width: calc(100% - 48px);
    }

    .input-text {
        margin-bottom: 10px;
    }

    .dynamic-table {
        width: 100%;
    }
</style>
