import { render, staticRenderFns } from "./create-provider-facebook-modal.vue?vue&type=template&id=7f2b8230&scoped=true"
import script from "./create-provider-facebook-modal.vue?vue&type=script&lang=js"
export * from "./create-provider-facebook-modal.vue?vue&type=script&lang=js"
import style0 from "./create-provider-facebook-modal.vue?vue&type=style&index=0&id=7f2b8230&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f2b8230",
  null
  
)

export default component.exports