<template>
    <b-modal v-model="show" :title="modalTitle" id="modal-prevent-closing">
        <div class="modal-container">
            <h5>Codes postaux</h5>
            <div>
                <label for="copyFormat" class="d-flex no-wrap mr-2">Mode de copie:</label>
                <b-row>
                    <b-col class="d-flex">
                        <select v-model="compSelectFormat" id="copyFormat" class="form-control mr-2" :disabled="!isZipCodeFound">
                            <option value="comma">Virgules</option>
                            <option value="space">Espaces</option>
                            <option value="newline">Lignes</option>
                        </select>
                        <span @click="dataToCopy" :style="{ cursor: isZipCodeFound ? 'pointer' : 'auto' }" data-toggle="tooltip" data-placement="top" title="copier">
                            <img src="@/assets/images/icons/button-copy.png" alt="Copy" style="width: 32px" />
                        </span>
                    </b-col>
                </b-row>
            </div>
            <div>
                <pre class="prestyle" v-if="isZipCodeFound">{{ formattedZipcodes }}</pre>
                <pre class="prestyle" v-else style="text-align: center">Pas de codes postaux trouvés.</pre>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'CopyModal',
        props: {
            modalTitle: {
                type: String,
                required: true,
            },
            pointCode: {
                type: String,
                default: '',
                required: true,
            },
            brand: {
                type: String,
                required: true,
            },
            selectedFormat: {
                type: String,
                default: 'comma',
            },
        },
        data() {
            return {
                compSelectFormat: this.selectedFormat,
                localZipcode: '',
                isZipCodeFound: true,
                show: true,
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),

            formattedZipcodes() {
                if (!Array.isArray(this.localZipcode)) {
                    return '';
                } else {
                    switch (this.compSelectFormat) {
                        case 'comma':
                            return this.localZipcode.join(' , ');
                        case 'space':
                            return this.localZipcode.join(' ');
                        case 'newline':
                            return this.localZipcode.join('\n');
                        default:
                            return this.localZipcode.join(' , ');
                    }
                }
            },
        },
        methods: {
            async dataToCopy() {
                if (!this.isZipCodeFound) {
                    return;
                }
                try {
                    let zipCodeText = this.formattedZipcodes;
                    if (this.compSelectFormat === 'comma') {
                        zipCodeText = zipCodeText
                            .split(',')
                            .map((code) => code.trim())
                            .join(',');
                    }
                    await navigator.clipboard.writeText(zipCodeText);
                    this.$emit('show-toast', { type: 'success', message: 'Copié' });
                } catch (err) {
                    this.$emit('show-toast', { type: 'error', message: 'Une erreur est survenue' });
                }
            },

            async getZipCodes(pointCode, brand) {
                this.$root.toggleGlobalLoading(true);

                const payload = {
                    point_code: pointCode,
                    brand: brand,
                };
                try {
                    const response = await this.$store.dispatch('app/getZipcode', payload);

                    if (response.data.length > 0) {
                        this.localZipcode = response.data;
                        this.isZipCodeFound = true;
                    } else {
                        this.isZipCodeFound = false;
                    }
                } catch (error) {
                    console.log(error);
                }

                this.$root.toggleGlobalLoading(false);
                this.show = true;
            },
        },
        watch: {
            selectedFormat(newVal) {
                this.compSelectFormat = newVal;
            },
            compSelectFormat(newVal) {
                this.$emit('update:selectedFormat', newVal);
            },
            localZipcode(newVal) {
                this.localZipcode = newVal;
            },
            show(newVal) {
                if (!newVal) {
                    this.$emit('close');
                }
            },
        },
        beforeMount() {
            if (this.pointCode) {
                this.getZipCodes(this.pointCode, this.brand);
            } else {
                console.log('Pas de point code');
            }
        },
    };
</script>

<style scoped>
    .modal-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
    .zipcode-list {
        margin-top: 20px;
        font-size: 14px;
        background-color: #f9f9f9;
        padding: 10px;
        border-radius: 5px;
        max-height: 200px;
        overflow-y: auto;
    }

    #copyFormat {
        padding: 5px;
        font-size: 15px;
    }

    .prestyle {
        white-space: pre-wrap;
        padding: 10px;
        overflow-x: hidden;
    }

    .copy-button img {
        width: 32px;
    }
</style>
